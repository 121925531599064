/* --------------------------------
   footer
-------------------------------- */

.footer
{
	position: relative;
	font-size: 1.6rem;

	&__line
	{
		position: relative;
	}

	&__item
	{
		position: relative;
		width: 100%;
		float: left;
		margin-top: 20px;
		margin-bottom: 20px;
		z-index: 2;
	}

	&__navigation
	{
		line-height: 1.4;
		font-weight: 700;

		&:first-child { margin-top: -10px; }

		ul {  }

		li
		{
			margin-top: 10px;

			&.active
			{
				a { text-decoration: underline; }
			}
		}

		a
		{
			color: inherit;
			text-decoration: none;
		}
	}

	&__address
	{
		font-style: normal;

		&--s1
		{
			line-height: 1.8;
			font-weight: 700;
		}

		&--s2
		{
			line-height: 1.6;
			font-weight: 700;

			ul
			{

			}

			li
			{
				margin-top: 15px;
				padding-left: 35px;

				&:first-child { margin-top: 0 !important; }

				&:after
				{
					content: "";
					display: table;
					clear: left;
				}
			}

			.__ico
			{
				float: left;
				margin-left: -35px;
				width: 1em;
				line-height: 1;
				font-size: 2.3rem;

				&:before { vertical-align: top; }
			}
		}

		&--s3
		{
			line-height: 1.2;
		}

		p
		{
			margin-top: 10px;
			margin-bottom: 10px;

			&:first-child { margin-top: 0 !important; }
			&:last-child  { margin-bottom: 0 !important; }
		}

		a { color: inherit; }
	}

	&__wave
	{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}

	.__copy { font-size: 1.4rem; }

	.__dev
	{
		color: inherit;

		&:hover,
		&:focus
		{
			text-decoration: none;
		}
	}
}

.footer--color-light
{
	color: $white;

	.footer__item__title { color: inherit; }

	.footer__navigation
	{
		li
		{
			&.active
			{
				a { color: #798694; }
			}
		}

		a
		{
			&:hover { color: #798694; }
		}
	}

	.footer__address
	{
		&--s3
		{
			strong {  }
		}
	}
}

.footer--color-dark
{
	color: inherit;

	.footer__navigation
	{
		li
		{
			&.active
			{
				a { color: #d6d6d6; }
			}
		}

		a
		{
			&:hover { color: #d6d6d6; }
		}
	}

	.footer__address
	{
		&--s3
		{
			strong { color: #333; }
		}
	}
}

.footer--s1
{
	.footer__line
	{
		&--first
		{
			background-color: #2d3a49;
			padding-top: 100px - 20px;
			padding-bottom: 100px - 20px;
		}
	}

	.__copy { color: #898b8f; }

	.footer__wave { height: 200px; }
}

.footer--s2
{
	.footer__line
	{
		&--first { padding-top: 95px - 20px; }

		&--second { padding-bottom: 20px; }
	}

	.__copy { color: #a4a4a4; }

	.footer__wave { height: 250px; }
}

.footer--s3
{
	.footer__line
	{
		&--first
		{
			background-color: #f9fbfc;
			padding-top: 70px - 20px;
			padding-bottom: 90px - 20px;
		}

		&--second
		{
			background-color: #2d3a49;
			padding-top: 30px - 20px;
			padding-bottom: 30px - 20px;
		}
	}

	.__copy { color: $white; }

	.footer__wave { height: 150px; }
}

.footer--s4
{
	.footer__line
	{
		&--first
		{
			background-color: #2d3a49;
			padding-top: 80px - 20px;
			padding-bottom: 100px - 20px;
		}
	}

	.__copy { color: #898b8f; }

	.footer__wave { height: 200px; }
}

.footer--s5
{
	.footer__line
	{
		&--first
		{
			padding-top: 95px - 20px;
			padding-bottom: 95px - 20px;
		}
	}

	.__copy { color: #afb3b9; }

	.footer__wave { height: 250px; }
}

.footer--s6
{
	.footer__line
	{
		&--first
		{
			background-color: #2d3a49;
			padding-top: 50px - 20px;
			padding-bottom: 70px - 20px;
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}