/* --------------------------------
   team
-------------------------------- */

$gutter: 50px;

%s-btns
{
	&
	{
		margin-top: 20px;
		line-height: 1;

		a
		{
			margin-left: 20px;
			font-size: 2rem;
			color: #3e3e3e;

			&:first-child { margin-left: 0; }

			&:hover,
			&:focus
			{
				color: #aaa;
			}
		}
	}
}

.team
{
	.__inner { margin-bottom: -$gutter; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $gutter;
		text-align: center;
	}

	.__image
	{
		position: relative;
		margin: auto;
		overflow: hidden;

		img { @include object-fit(cover, 50% 50%); }
	}

	.__content
	{
		padding-top: 25px;
		line-height: 1.2;
		color: #888;
	}

	.__name { margin-bottom: 5px; }

	.__position { font-size: 1.6rem; }

	.__soc-btns
	{
		a
		{
			display: inline-block;
			vertical-align: top;
			width: 1em;
			text-decoration: none;
			@include transition(
				background-color 0.3s ease-in-out,
				border-color     0.3s ease-in-out,
				color            0.3s ease-in-out
			);
		}
	}
}

.team--s1
{
	.__image
	{
		line-height: 200px;
	}

	.__content { margin-top: -5px; }

	.__soc-btns { @extend %s-btns; }
}

.team--s2
{
	.__item
	{
		&:hover
		{
			.__image img { transform: scale(1.2) translateZ(0); }
		}
	}

	.__image
	{
		height: 0;
		padding-top: percentage(400/370);

		&--rounded { border-radius: 5px; }

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
				opacity 200ms
			);
		}
	}

	.__soc-btns { @extend %s-btns; }
}

.team--s3
{
	.__image
	{
		display: inline-block;
		vertical-align: top;
		overflow: visible;

		img { border-radius: 50%; }
	}

	.__soc-btns
	{
		a
		{
			position: absolute;
			top: 0;
			right: 0;
			width: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 1.5rem;
			color: $white;
		}

		.fontello-linkedin { background-color: #0e76a8 }
		.fontello-facebook { background-color: #3b5998 }
		.fontello-gplus    { background-color: #dd4b39 }
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}