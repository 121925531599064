/* --------------------------------
   sidebar
-------------------------------- */

.sidebar
{
	position: relative;
	min-height: 100%;
	transform: translateZ(0);
	z-index: 3;
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}