/* --------------------------------
   widget
-------------------------------- */

.widget
{
	position: relative;
	margin-top: 40px;

	&:first-child
	{
		margin-top: 0;

		.widget-title { margin-top: -0.2em; }
	}
}

.widget--categories
{
	.list
	{
		&__item
		{
			margin-top: 15px;
			line-height: 1.2;
			font-size: 1.6rem;
			font-weight: 700;

			&:first-child { margin-top: 0; }

			&__link
			{
				display: block;
				text-decoration: none;

				&:not(:hover) { color: #333; }
			}

			span
			{
				margin-left: 15px;
				float: right;
			}
		}
	}
}

.widget--posts
{
	article
	{
		margin-top: 20px;
		line-height: 1.4;

		&:first-child { margin-top: 0; }
	}

	.__image-wrap
	{
		width: 34%;
		max-width: 100px;
		padding-right: 20px;
	}

	.__image
	{
		position: relative;
		width: 100%;
		height: 0;
		margin: auto;
		padding-top: 100%;
		overflow: hidden;

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include object-fit(cover, 50% 50%);
		}
	}

	.__title { margin-bottom: 7px; }
}

.widget--tags
{
	
}

.widget--banner
{
	
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{

}