/* --------------------------------
   company contacts
-------------------------------- */

.company-contacts
{
	
}

.company-contacts--s1
{
	> .__inner { margin-bottom: -25px; }

	.__item { margin-bottom: 25px; }

	.__name { margin-bottom: 10px; }

	p
	{
		margin-top: 10px;
		margin-bottom: 10px;

		a { color: inherit; }
	}
}

.company-contacts--s2
{
	&.text-white
	{
		.__name { color: inherit; }
	}

	.__phone
	{
		line-height: 1.2;
		font-size: 3.5rem;
		font-weight: 700;
		font-family: $fontFamily-secondary;
		letter-spacing: -0.03em;

		@include media-breakpoint-up(sm)
		{
			font-size: 5rem;
		}
	}

	a:not([class]) { color: inherit; }
}

.company-contacts--s3
{
	$space: 50px;

	.company-contacts__list
	{
		line-height: 1.3;

		li
		{
			margin-top: 20px;
			padding-left: $space;

			&:first-child { margin-top: 0; }

			&:after
			{
				content: "";
				display: table;
				clear: left;
			}
		}
	}

	.__ico
	{
		float: left;
		width: 1em;
		margin-left: -$space;
		line-height: 1;
		font-size: 2.5rem;
		color: $primary-color;
	}

	a:not([class]) { color: inherit; }
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}