/* --------------------------------
   steps
-------------------------------- */

$gutter: 40px;

.steps
{
	counter-reset: step-num;

	.__inner { margin-bottom: -$gutter; }

	.__item
	{
		position: relative;
		width: 100%;

		.__title { margin-bottom: 15px; }

		p
		{
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
}

.steps--s1
{
	.__item
	{
		margin-bottom: $gutter;
		text-align: center;

		.__num
		{
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 120px;
			height: 120px;
			background-color: lighten(desaturate(adjust-hue($primary-color, 11), 56.28), 60.20);
			border: 2px solid lighten(desaturate(adjust-hue($primary-color, 2), 46.91), 58.24);
			line-height: 116px;
			font-size: 4rem;
			font-weight: 700;
			font-family: $fontFamily-secondary;
			color: $primary-color;
			border-radius: 50%;
			@include userSelect(none);

			&:before
			{
				display: inline;
				counter-increment: step-num;
				content: counter(step-num, decimal-leading-zero);
			}

			.__ico
			{
				position: absolute;
				top: -5px;
				right: -10px;
				width: 40px;
				height: 40px;
				background-color: #44c380;
				line-height: 40px;
				font-size: 1.8rem;
				color: $white;
				border-radius: 50%;
			}
		}
	}
}

.steps--s2
{
	.tab-nav
	{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: -15px;
		margin-bottom: 20px;

		@include media-breakpoint-up(md)
		{
			margin-bottom: 40px;
		}

		@include media-breakpoint-up(lg)
		{
			margin-left: -20px;
		}

		&__item
		{

			flex: 1 0 100%;
			margin-left: 15px;
			padding: 0;
			border-color: transparent;
			font-weight: 700;
			text-align: left;
			color: #333;

			&.active
			{
				border-color: $primary-color;
				color: $primary-color;
			}

			@include media-breakpoint-up(sm)
			{
				flex: 1;
			}

			@include media-breakpoint-up(lg)
			{
				margin-left: 20px;
			}
		}

		&__link
		{
			display: inline-block;
			padding: 10px 0;
			border-bottom: 2px solid;
			border-color: inherit;
			color: inherit;

			&:before
			{
				display: inline;
				counter-increment: step-num;
				content: counter(step-num, decimal-leading-zero) ". ";
			}
		}
	}

	.__item
	{
		padding: 25px 15px;
		background-color: $white;

		@include media-breakpoint-up(sm)
		{
			padding: 25px 30px;
		}

		@include media-breakpoint-up(md)
		{
			padding: 30px 40px;
		}

		@include media-breakpoint-up(lg)
		{
			padding: 50px 60px;
		}
	}
}

.steps--s3
{
	.__item
	{
		margin-bottom: $gutter;

		@include media-breakpoint-up(md)
		{
			padding-left: 100px;
			padding-left: 18%;
		}

		.__num
		{
			position: absolute;
			top: 95px;
			left: 4%;
			width: 1em;
			line-height: 1;
			font-size: 10rem;
			font-weight: 700;
			font-family: $fontFamily-secondary;
			color: #eef4f9;
			z-index: -1;

			&:before
			{
				display: inline;
				counter-increment: step-num;
				content: counter(step-num, decimal-leading-zero);
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}