/* --------------------------------
   video
-------------------------------- */

.video-box
{
	text-align: center;

	.__image
	{
		position: relative;
		width: 100%;
		height: 0;
		min-height: 220px;
		margin: auto;
		overflow: hidden;

		&--rounded
		{
			border-radius: 10px;

			.btn-play-link { border-radius: inherit; }
		}

		img,
		.btn-play-link
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		img
		{
			@include object-fit(cover, 50% 50%);
		}

		.btn-play
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
}

.video-box--s2
{
	.__image
	{
		padding-top: percentage(515/770);
	}
}

.btn-play-link
{
	display: inline-block;
	vertical-align: top;

	&:hover
	{
		.btn-play { background-color: #2d3a49; }
	}
}

.btn-play
{
	position: relative;
	display: block;
	width: 80px;
	height: 80px;
	background-color: $primary-color;
	border-radius: 20px;
	@include transition(
		background-color 0.3s ease-in-out,
		color            0.3s ease-in-out
	);

	&:before
	{
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 7px;
		margin: auto;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 12px 0 12px 15px;
		border-color: transparent transparent transparent $white;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}