/* --------------------------------
   authorization
-------------------------------- */

.authorization
{
	padding: 30px 0;

	.site-logo { margin-bottom: 30px }

	&__form
	{
		width: 100%;
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
		padding: 40px 20px;
		background-color: $white;
		font-size: 1.6rem;

		&--shadow { box-shadow: 0px 0px 68px 0px rgba(#aeafaf, 0.17); }

		.__title
		{
			margin-bottom: 30px;
			text-align: center;
		}
	}
}

@include media-breakpoint-up(sm)
{
	.authorization
	{
		.site-logo { margin-bottom: 40px }

		&__form { padding: 60px 30px; }
	}
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}